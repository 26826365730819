// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
// helpers
import { up, addResponsivity } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  ContactItem,
  CallMeForm,
  Button,
  WaveImage,
} from "../components"
import ProcedureItem from "../components/ProcedureItem"

// assets
// import heroImage from '../data/images/logo.png'

// custom styled components
const MapImage = styled(GatsbyImage)`
  ${({ theme }) => css`
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  `}
`
const MapImageWrapper = styled.div`
  ${({ theme: { mobilePageSidePadding } }) => css`
    margin: 0 -${mobilePageSidePadding};
    ${up(850)} {
      margin: 0;
    }
  `}
`

const ContactGap = () => <Gap gap="32px" />

class Contact extends React.Component {
  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data: { mapImage } = {},
    } = this.props
    return (
      <>
        <SEO
          title={t("contact - meta title")}
          description={t("contact - meta desc")}
          pathname={pathname}
        />

        <Row justifyContent="space-between" responsive wrap="nowrap" bp={850}>
          <Col>
            <Gap.Top />
            <Title.PageTitle>
              <T>FORMÉ clinic</T>
            </Title.PageTitle>
            <Gap gap="44px" />
            <ContactItem.Address />
            <ContactGap />
            <ContactItem.Hours />
            <ContactGap />
            <ContactItem.Phone language={language} />
            <ContactGap />
            <ContactItem.Email />
            <ContactGap />
            <ContactItem.EmergencyPhone />
            <ContactGap />
            <ContactItem.Parking />
            <Gap gap="42px" />

            {/* <Col justifyContent="flex-start" alignItems="flex-start">
              <WaveImage />
              <Gap gap="24px" />
              <Title.BigTitle>
                <T>Ortopedie</T>
              </Title.BigTitle>
              <Gap gap="24px" />
              <ContactItem.Orthopedics />
            </Col> */}

            <Gap gap="40px" />
            <CallMeForm.Basic />
            <ContactGap />
          </Col>
          <Gap gap="24px" mobileGap="8px" />
          <Col grow={1}>
            <ContactItem.MapLink>
              <MapImageWrapper>
                <MapImage image={mapImage.image.gatsbyImageData} />
              </MapImageWrapper>
            </ContactItem.MapLink>
          </Col>
        </Row>

        <Gap gap="80px" mobileGap="30px" />
        <ContactFormSection ctaName="contact" />
      </>
    )
  }
}

Contact.defaultProps = {
  data: {},
}

Contact.propTypes = {
  data: PropTypes.object,
}

export default injectIntl(Contact)

// TODO: REVIEW: pokud mas fragment nad tim a nepouzivas ho znova, tak nemusis delat fragment! pripadne presun do fragments.js
// je dobry pridavat quality: 100, pokud je to nekde velky a na viditelnym miste
export const pageQuery = graphql`
  fragment MapImage on File {
    image: childImageSharp {
      gatsbyImageData(
        width: 650
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }

  {
    mapImage: file(relativePath: { eq: "images/map.png" }) {
      ...MapImage
    }
  }
`
